<template>
  <b-card class="h-100">
    <validation-observer
      ref="userTypePermission"
    >
      <b-form
        method="POST"
        autocomplete="off"
        @submit.prevent="saveUserTypePermission"
      >
        <b-row>
          <!-- <b-col md="12">
        <b-form-group>
          <b-form-checkbox
            id="copy_permission"
            v-model="copy_check"
            name="copy_permission"
          >
            <span class="pl-1">Copy Permission</span>
          </b-form-checkbox>
        </b-form-group>

      </b-col> -->
          <b-col md="12 pl-0">
            <b-form-group
              :label="t('User Type')"
              label-for="id"
              class="col-4"
            >
              <validation-provider
                #default="{ errors }"
                name="User"
                vid="vi-user"
                :rules="selectReq ? 'required' : ''"
              >
                <v-select
                  id="vi-user"
                  v-model="userTypeSelected"
                  class="p-0 border-0"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :placeholder="t('Select User Type')"
                  label="group_desc"
                  :reduce="user => user.group_id"
                  :options="userType"
                  @input="changeUser"
                />
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <!-- <b-col md="6">
        <b-form-group
          v-if="copy_check"
          label="From User"
          label-for="id"
        >
          <v-select
            id="id"
            v-model="copyFromSelected"
            name="inputname"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            placeholder="Select"
            label="name"
            :reduce="user => user.companyuserid"
            :options="user"
          />
        </b-form-group>

      </b-col> -->
          <b-col
            v-for="(per, index) in permission"
            :key="per.trngroupid"
            md="4"
            class="py-1"
          >
            <b-card
              class="h-100 permissionCard"
            >
              <template #header>
                <b-form-checkbox
                  :id="`header-checkbox-${index}`"
                  :checked="checkIncludes(index)"
                  :name="per.trngrouptitle + per.trngroupid"
                  @change="checkAllPermission(index, $event)"
                >
                  <span> {{ t(per.trngrouptitle) }} </span>
                </b-form-checkbox>
              </template>

              <b-form-checkbox
                v-for="trans in per.transaction"
                :key="trans.trnid"
                v-model="permissionSelected"
                class="my-1"
                :name="`trngroup-${trans.trnid}`"
                :value="trans.trnid"
                @change="checkUncheckHeaderCheckbox(index, $event)"
              >
                <span> {{ t(trans.trnname) }} </span>
              </b-form-checkbox>

            </b-card>

          </b-col>
          <!-- reset and submit -->
          <b-col
            cols="12"
            class="text-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              {{ t('Submit') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="reset"
            >
              {{ t('Reset') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BCard, BForm, BFormGroup, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useUtils } from '@core/libs/i18n'
import $ from 'jquery'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '../../../store'
import { showLoader, hideLoader } from '@/utils/common'
import constants from '@/constants'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    BCard,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      userType: [],
      permission: [],
      userTypeSelected: '',
      permissionSelected: [],
      required,
      user: [],
      copy_check: false,
      userSelected: '',
      copyFromSelected: '',
      selectReq: true,
    }
  },
  mounted() {
    if (this.$route.params.role_id) {
      this.userTypeSelected = this.$route.params.role_id
      this.changeUser()
    }
    store.dispatch('CompanyPermission/getPermissionSettings')
      .then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.permission = response.data.data
        }
      })
    store.dispatch('CompanyuserManage/getUserType')
      .then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.userType = response.data.data
        }
      })
  },
  methods: {
    reset() {
      this.copy_check = false
      this.userTypeSelected = ''
      this.copyFromSelected = ''
      this.permissionSelected = []
    },
    checkUncheckHeaderCheckbox(pid, val) {
      if (val.length) {
        this.permission[pid].transaction.forEach(e => {
          if (this.permissionSelected.indexOf(e.trnid) !== -1) {
            $(`#header-checkbox-${pid}`).prop('checked', false)
          }
          if (this.checkIncludes(pid)) {
            $(`#header-checkbox-${pid}`).prop('checked', true)
          }
        })
      }
    },
    checkIncludes(pid) {
      return this.permission[pid].transaction.every(e => this.permissionSelected.includes(e.trnid))
    },
    checkAllPermission(pid, val) {
      if (val) {
        this.permission[pid].transaction.forEach(e => {
          if (this.permissionSelected.indexOf(e.trnid) === -1) {
            this.permissionSelected.push(e.trnid)
          }
        })
      } else {
        this.permission[pid].transaction.forEach(e => {
          const index = this.permissionSelected.indexOf(e.trnid)
          if (index !== -1) {
            this.permissionSelected.splice(index, 1)
          }
        })
      }
    },
    async changeUser() {
      showLoader()
      await store.dispatch('CompanyPermission/getUserTypePermissions', { group_id: this.userTypeSelected })
        .then(response => {
          if (response.data.code === constants.SUCCESS) {
            this.permissionSelected = response.data.data
            hideLoader()
          }
        })
      hideLoader()
    },
    saveUserTypePermission() {
      this.$refs.userTypePermission.validate().then(async success => {
        if (success) {
          showLoader()
          await store.dispatch('CompanyPermission/saveUserTypePermissions', { group_id: this.userTypeSelected, transactionIds: this.permissionSelected })
            .then(response => {
              if (response.data.code === constants.SUCCESS) {
                this.userTypeSelected = ''
                this.permissionSelected = []
                this.$refs.userTypePermission.reset()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `${response.data.msg}`,
                    icon: 'Success',
                    variant: 'success',
                    text: '',
                  },
                })
                hideLoader()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `${response.data.msg}`,
                    icon: 'Danger',
                    variant: 'danger',
                    text: '',
                  },
                })
              }
            }).catch(e => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${e.msg}`,
                  icon: 'Danger',
                  variant: 'danger',
                  text: '',
                },
              })
              hideLoader()
            })
          hideLoader()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
  .card.permissionCard{
    max-height: 350px;
    margin-bottom: 0;
    border: 1px solid rgba(99, 99, 248, 0.199);
    box-shadow: 1px 1px 12px rgba(99, 99, 248, 0.199) !important;
  }
  .permissionCard .card-header {
    background: linear-gradient(to right , rgba(99, 99, 248, 0.199), rgba(99, 99, 248, 0.199), rgba(255, 128, 255, 0.281));
    font-weight: bold;
  }
  .card-body {
    overflow: auto;
    margin-bottom: 1.5rem;
  }
  .card-body::-webkit-scrollbar {
    display: none;
  }
  .right-20 {
    right: 20px;
  }
</style>
